// import React from 'react'
import React, { useState, useEffect, useContext } from 'react'
import { InsightText, TreeTable, Switch, Button, Gauge, Tooltip, PageLoader } from "freespace-react-components";
import HealthOverviewContext from '../../context/HealthOverviewContext';

const AccountWiseHealth = () => {
    const { 
        quickInfoData, 
        overallInfo, 
        insightText, 
        liveTreeAPIData, 
        Products 
    } = useContext<any>(HealthOverviewContext);



    const [showQuickInfo, setShowQuickInfo] = useState(false);
    const [liveTreeData, setLiveTreeData] = useState(liveTreeAPIData);
    const [averagePercentages, setAveragePercentages] = useState<object>({})
    const [overallPercentage, setOverallPercentage] = useState();
    const [accountName, setAccountName] = useState();
    const [riskView, setRiskView] = useState(true);


    useEffect(() => {
        setAveragePercentages(quickInfoData)
        setOverallPercentage(overallInfo)
    }, [quickInfoData, overallInfo])

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('spaceio-user'));
        setAccountName(userInfo?.account?.name || 'Unknown Account');
    }, []);


    useEffect(() => {
        if (liveTreeAPIData) {
            setLiveTreeData(liveTreeAPIData);
        }
    }, [liveTreeAPIData]);



    return (
        <div className="flex w-full h-[100vh]">
            <div className="flex flex-col w-full"  >
                <div className=" flex w-full h-full divide-x divide-dividerLines bg-webBg">
                    <div className="w-full flex flex-col justify-between ">
                        <div className={`${showQuickInfo ? 'w-28' : 'w-full'}`} >
                            <div style={{backgroundColor: 'rgba(243, 252, 248, 1)',boxShadow: '0px 4px 4px 0px #0000000F'}} className={`flex bg-accent1Rollover h-16 p-2 justify-between overflow-x-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style]:none [scrollbar-width]:none`}>
                                {insightText.map(item => (
                                    <InsightText
                                        key={item.id}
                                        primaryText={
                                            <div>
                                                <p className='text-xs' style={{color:'#1E253E',fontSize:'12px'}}>{`${item.id} - ${item.name} `}</p>
                                            </div>
                                        }
                                        secondaryText={
                                            <div className='flex flex-col text-xs'>
                                                <p className='text-xs' style={{color:'#6D7492',fontSize:'12px'}}>{`${item.anchorAddress.split(',')[0]?.trim()} - ${item.anchorAddress.split(',')[1]?.trim()}`}</p>
                                            </div>
                                        }
                                        contentInput={
                                            <p className="text-xs" style={{color:'#FF5C5C',fontSize:'12px',fontWeight:'700'}}>
                                                {item.faultyPercentage}%
                                            </p>
                                        }
                                    />
                                ))}
                            </div>
                            {liveTreeData ? (
                                <TreeTable
                                    data={liveTreeData}
                                    commonColumsName={['Name', 'Type', 'Id', 'Overall']}
                                    columnsName={Products}
                                    scrollHeight={'540px'}
                                    // minWidth={'200px'}
                                    extraTableHeaderElement={[
                                        <>
                                             <div key="in-risk-view" className='flex justify-center items-center gap-2'>
                                            <p className='text-xs lg:text-sm font-normal'>In-Risk View</p>
                                            <Switch
                                                checkedStatus={riskView}
                                                onChange={(isChecked) => {
                                                    setRiskView(isChecked)
                                                }}
                                            />
                                        </div>
                                        <div key="clear-filter" className='flex justify-center items-center gap-2'>
                                            <p className='quickInfo'>
                                                <div key="clear-filter" className='flex justify-center items-center gap-2'>
                                                    <Button
                                                        size="small"
                                                        icon='pi pi-sparkles'
                                                        outlined={true}
                                                        onClick={() => {
                                                            setShowQuickInfo(!showQuickInfo);
                                                        }} />
                                                </div>
                                            </p>

                                            <Tooltip
                                                position={'bottom'}
                                                event={'hover'}
                                                disabled={false}
                                                target={'.quickInfo'}>
                                                <p className='text-xs p-0.5'>Quick Info</p>
                                            </Tooltip>
                                        </div>
                                        </>
                                       
                                    ]}
                                />

                            ) : 
                            <PageLoader loading={true} />
                            }
                        </div>
                    </div>

                    {
                        showQuickInfo && (
                            <div className='w-1/5 bg-customGray flex flex-col items-start' style={{ width: '23%', gap: '17px', borderRight: '2px' }}>
                                <p className='font-serif font-medium text-base pl-3 ' style={{ color: '#1E253E',paddingTop:'14px',paddingLeft:'13px'}}>Quick info</p>
                               
                                <div className=" text-xs font-medium items-center flex flex-col w-full" >
                                    <div className='pb-2' style={{ width: '12rem' }}>
                                        <Gauge
                                            percentage={10}
                                            lableOn={true}
                                            left={<p className='text-xs justify-center' style={{color:'#6D7492',fontWeight:'700'}}>Low Risk</p>}
                                            right={<p className='text-xs justify-center' style={{color:'#6D7492',fontWeight:'700'}}>High Risk</p>}
                                        />
                                    </div>
                                    <div className=" text-sm items-center flex flex-col" style={{color:'#555555',fontWeight:'700'}}>
                                        <span
                                            className="font-semibold font-sans"
                                            style={{ color:'#555555', textAlign: "center" }}>
                                            {accountName} is at
                                        </span>
                                        <span className="font-semibold font-sans text-[#555555]  " style={{color:'#555555'}}>
                                            {Math.floor(parseFloat(overallPercentage))}% {overallPercentage ? (overallPercentage > 30 ? 'Low Risk' : 'High Risk') : 'No Information Available'}
                                        </span>
                                    </div>
                                </div>
                                <hr className="w-full border-t border-gray-300" />
                                {Object.keys(averagePercentages).map((key) => (
                                    <>
                                        {averagePercentages[key]?.total > 0 && (
                                            <div className="flex gap-4 items-center"style={{ paddingTop: '10px', paddingLeft: '23px' }} > 
                                           
                                                <div style={{ width: '70px' }} >
                                                    <Gauge
                                                        percentage={parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2)) > 100 || parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2)) < 0 ? 0 : parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2))} // Set percentage based on the average percentage for the key
                                                        lableOn={false} // Assuming this is a prop for the Gauge component to toggle label visibility
                                                    />
                                                </div>
                                                <p className="font-sans text-sm font-bold " style={{color: '#1E253E'}}>{averagePercentages[key]?.total > 0 ? Math.floor((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100) : 0}% {key} </p>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                        )
                    }
                </div>

            </div>
        </div>
    )

}

export default AccountWiseHealth;